import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { format } from 'date-fns';
import { formatUSD } from '@shared/lib/numbers';
import { CopiersIndex, DateIndex, IncomeIndex, PayoutAvailableIndex, PayoutIndex, RefundsIndex, VisitorsIndex, } from '../TraderPlatformBalanceHeader/ActivityTable/utils';
const ActivityCell = ({ cellParams, }) => {
    const getValue = () => {
        switch (cellParams.rowId) {
            case DateIndex:
                return format(new Date(cellParams.value), 'dd, ccc');
            case VisitorsIndex:
            case CopiersIndex:
                if (cellParams.value && !Number.isNaN(Number(cellParams.value))) {
                    return cellParams.value;
                }
                return '-';
            case IncomeIndex:
            case PayoutIndex:
            case RefundsIndex:
            case PayoutAvailableIndex:
                if (cellParams.value && !Number.isNaN(Number(cellParams.value))) {
                    return formatUSD(cellParams.value, { maximumFractionDigits: 0 });
                }
                return '-';
            default:
                return cellParams.value;
        }
    };
    return _jsx(_Fragment, { children: getValue() });
};
export default ActivityCell;
