import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import IconText from '@shared/ui/icons/IconText';
import { ReactComponent as EyeIcon } from '@icons/wolfkit-light/eye-light.svg';
import { ReactComponent as PersonIcon } from '@icons/wolfkit-light/users-light.svg';
import { ReactComponent as WalletIcon } from '@icons/custom/wallet-light.svg';
import { ReactComponent as IncomeIconComponent } from '@icons/custom/income-light.svg';
import { ReactComponent as RefundsIcon } from '@icons/wolfkit-light/arrow-2-cw-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { BodySmall } from '@src/components/styled/Typography';
const IncomeIcon = styled(IncomeIconComponent)(props => ({
    path: {
        fill: props.theme.palette.success.main,
    }
}));
const DateHeader = styled(BodySmall)(props => ({
    color: props.theme.palette.text.secondary,
}));
const ActivityHeaderCell = ({ cellParams, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const getProps = (rowId) => {
        switch (rowId) {
            case 1:
                return {
                    text: t('balance.trader_balance.activity_table.rows.visitors'),
                    Icon: EyeIcon,
                };
            case 2:
                return {
                    text: t('balance.trader_balance.activity_table.rows.copiers'),
                    Icon: PersonIcon,
                };
            case 3:
                return {
                    text: t('balance.trader_balance.activity_table.rows.income'),
                    Icon: PersonIcon,
                };
            case 4:
                return {
                    text: t('balance.trader_balance.activity_table.rows.payout'),
                    Icon: IncomeIcon,
                };
            case 5:
                return {
                    text: t('balance.trader_balance.activity_table.rows.refunds'),
                    Icon: RefundsIcon,
                };
            case 6:
                return {
                    text: t('balance.trader_balance.activity_table.rows.available_payout'),
                    Icon: WalletIcon,
                };
            default:
                return { text: '', Icon: undefined };
        }
    };
    if (cellParams.rowId === 0) {
        return (_jsx(DateHeader, { children: t('balance.trader_balance.activity_table.rows.date') }));
    }
    return (_jsx(IconText, { IconComponent: getProps(cellParams.rowId).Icon && (_jsx(Icon, { IconComponent: getProps(cellParams.rowId).Icon })), text: getProps(cellParams.rowId).text, textColor: theme.palette.text.primary, textFontWeight: 400 }));
};
export default ActivityHeaderCell;
