import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactComponent as PortfolioIncomeIcon } from '@icons/custom/income-light.svg';
import { ReactComponent as PortfolioOutcomeIcon } from '@icons/custom/expences-light.svg';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
const TransactionTypeContainer = styled.div(props => ({
    height: '100%',
    padding: `${props.theme.spacing_sizes.xm}px 0px`,
    boxSizing: 'border-box',
}));
const TransactionTypeCell = ({ cellParams, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const getCellProps = () => {
        switch (cellParams.rowData.type) {
            case 'MANAGEMENT_INCOME':
                return {
                    Icon: PortfolioIncomeIcon,
                    text: t('balance.transaction_history_table.columns.type.income'),
                    color: theme.palette.error.main,
                };
            case 'PAYOUT':
                return {
                    Icon: PortfolioIncomeIcon,
                    text: t('balance.transaction_history_table.columns.type.payout'),
                    color: theme.palette.success.main,
                };
            case 'REFUND':
                return {
                    Icon: PortfolioIncomeIcon,
                    text: t('balance.transaction_history_table.columns.type.subscription_refund'),
                    color: theme.palette.success.main,
                };
            case 'SUBSCRIPTION':
                return {
                    Icon: PortfolioOutcomeIcon,
                    text: t('balance.transaction_history_table.columns.type.subscription'),
                    color: theme.palette.error.main,
                };
            default:
                return {
                    Icon: PortfolioIncomeIcon,
                    text: t('balance.transaction_history_table.columns.type.subscription'),
                    color: theme.palette.error.main,
                };
        }
    };
    return (_jsx(TransactionTypeContainer, { children: _jsx(IconText, { IconComponent: _jsx(Icon, { IconComponent: getCellProps().Icon, color: getCellProps().color }), text: getCellProps().text, textColor: theme.palette.text.primary, textFontWeight: 400 }) }));
};
export default TransactionTypeCell;
