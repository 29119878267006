import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { useRef } from 'react';
import IconText from '@shared/ui/icons/IconText';
import PaymentSystemIcon from '@shared/ui/icons/PaymentSystemIcon';
import { ReactComponent as CopyIconComponent } from '@icons/wolfkit-light/copy-square-light.svg';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { BodyMedium } from '@src/components/styled/Typography';
import { formatUSD } from '@shared/lib/numbers';
import CopyLink from '@shared/ui/misc/CopyLink';
import Button from '@shared/ui/buttons/Button';
const CopyIcon = styled(CopyIconComponent)(props => ({
    path: {
        fill: props.theme.palette.primary.main,
    }
}));
const PaymentMethodDetailsContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
    padding: `${props.theme.spacing_sizes.xm}px ${props.theme.spacing_sizes.s}px`,
}));
const MethodDetailsRowContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
}));
const MethodDetailsItem = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.s,
}));
const MethodDetailsRow = ({ title, value, }) => (_jsxs(MethodDetailsRowContainer, { children: [_jsx(MethodDetailsItem, { children: title }), _jsx(MethodDetailsItem, { children: value })] }));
const PaymentMethodCell = ({ cellParams, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const buttonCopyRef = useRef(null);
    const initialAmount = cellParams.rowData.details.initialAmount || 0;
    const platformFee = cellParams.rowData.details.platformFee || 0;
    return (cellParams.isExpanded ?
        _jsxs(PaymentMethodDetailsContainer, { children: [_jsx(MethodDetailsRow, { title: _jsx(BodyMedium, { children: t('balance.transaction_history_table.columns.payment_method.title') }), value: _jsx(BodyMedium, { children: cellParams.rowData.details.cardNumber }) }), _jsx(MethodDetailsRow, { title: _jsx(BodyMedium, { color: theme.palette.text.secondary, children: t('balance.transaction_history_table.columns.payment_method.init_amount') }), value: _jsx(BodyMedium, { color: theme.palette.text.secondary, children: formatUSD(initialAmount) }) }), _jsx(MethodDetailsRow, { title: _jsx(BodyMedium, { color: theme.palette.text.secondary, children: t('balance.transaction_history_table.columns.payment_method.platform_fee') }), value: _jsx(BodyMedium, { color: theme.palette.text.secondary, children: formatUSD(platformFee) }) }), _jsx(MethodDetailsRow, { title: _jsx(BodyMedium, { children: t('balance.transaction_history_table.columns.payment_method.recieved_amount') }), value: _jsx(BodyMedium, { children: formatUSD(cellParams.rowData.details.initialAmount || 0) }) }), _jsx(MethodDetailsRow, { title: _jsx(BodyMedium, { children: t('balance.transaction_history_table.columns.payment_method.order_id') }), value: _jsxs(_Fragment, { children: [_jsx(BodyMedium, { children: cellParams.rowData.details.orderId }), _jsx(CopyLink, { link: `${cellParams.rowData.details.orderId}`, ref: buttonCopyRef, children: _jsx(Button, { size: 'small', ref: buttonCopyRef, startIcon: CopyIcon, style: {
                                        maxHeight: 'unset',
                                        minHeight: 'unset',
                                        padding: 0,
                                        background: 'transparent',
                                    } }) })] }) })] }) :
        _jsx(IconText, { IconComponent: _jsx(PaymentSystemIcon, { type: cellParams.rowData.details.paymentSystem, size: 30 }), text: cellParams.rowData.details.cardNumber, containerGap: theme.spacing_sizes.m, textColor: theme.palette.text.primary, textFontWeight: 400 }));
};
export default PaymentMethodCell;
