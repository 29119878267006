import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Badge from '@shared/ui/badges/Badge';
const TransactionStatusContainer = styled.div(props => ({
    height: '100%',
    padding: `${props.theme.spacing_sizes.xm}px 0px`,
    boxSizing: 'border-box',
}));
const TransactionStatusCell = ({ cellParams, }) => {
    const { t } = useTranslation();
    return (_jsx(TransactionStatusContainer, { children: _jsx(Badge, { size: 'medium', variant: 'tinted', color: cellParams.rowData.status === 'ERROR' ? 'error' : 'success', children: cellParams.rowData.status === 'ERROR' ?
                t('balance.transaction_history_table.columns.status.failure') :
                t('balance.transaction_history_table.columns.status.success') }) }));
};
export default TransactionStatusCell;
