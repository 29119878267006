import { jsx as _jsx } from "react/jsx-runtime";
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { BodyMedium } from '@src/components/styled/Typography';
const DateCellContainer = styled.div(props => ({
    height: '100%',
    padding: `${props.theme.spacing_sizes.xm}px 0px`,
    boxSizing: 'border-box',
}));
const TransactionDateCell = ({ cellParams, }) => (_jsx(DateCellContainer, { children: _jsx(BodyMedium, { children: format(new Date(cellParams.rowData.date), 'yyyy-MM-dd hh:mm:ss') }) }));
export default TransactionDateCell;
